<template>
  <div class="bgCss">
    <div class="band-header">
      <div>{{buildingName}}实验室预约情况</div>
    </div>
    <div>
      <el-carousel style="height: 100vh;width: 100%;position: fixed;top:0" direction="vertical" interval="102000" motion-blur>
        <el-carousel-item v-for="(l,index) in appoinmentList" :key="index" style="height: 100vh;width: 100%;">
          <div class="ceneterTb"><div style="height: 55px"></div><span>{{l.buildC}}层</span></div>
          <div style="width: 100%">
            <div v-for="(s,index1) in l.roomList" class="roomCss" :style="RoomChangeCss(s.p)">
              <img :src="'/gloabImg/ap-'+s.state+'.png'" style="width: 166px;height: 208px" />
              <div style="position: relative;top: -180px;transform: rotate(20deg);font-size: 16px;color: #FFFFFF">
                <div style="color: white;font-family: PangMenZhengDao, PangMenZhengDao;">{{s.name}}</div><div style="font-size: 12px">{{s.p}}</div>
              </div>
              <div class="roomUseText" v-if="s.state==1">
                <div style="padding: 12px 0 4px 12px">预约人：{{s.userName}}</div>
                <div style="padding: 4px 0 0px 12px">用途：{{s.use}}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import editDiv from "@/view/building/buildingEdit.vue"
import {getBuildingPage} from "@/api/api";
export default {
  name: "buildingBand",
  data(){
    return{
      buildingId:'',
      buildingName:'',
      windowData:{
        innerHeight:88
      },
      appoinmentList:[
        {
          buildC:1,
          roomList:[{name:'测试实验室1',state:0,userName:'',useText:'',p:5},{name:'测试实验室2',state:1,userName:'xjb',useText:'撒啥事',p:31},
          {name:'测试实验室3',state:2,userName:'',useText:'',p:20},{name:'测试实验室4',state:1,userName:'xjb',useText:'撒sfs啥事',p:31}]
        },
        {
          buildC:2,
          roomList:[{name:'测试实验室15',state:0,userName:'',useText:'',p:2},{name:'测试实验室25',state:1,userName:'xjb',useText:'撒啥事',p:31},
            {name:'测试实验室35',state:2,userName:'',useText:'',p:20},{name:'测试实验室45',state:1,userName:'xjb',useText:'撒sfs啥事',p:31}]
        }
      ]
    }
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.buildingId=data.buildingId;
    this.buildingName=data.buildingName;
    let h=window.innerHeight- document.documentElement.clientHeight;
    console.log(window.outerHeight);
    console.log(window.innerHeight+'-'+document.documentElement.clientHeight+'='+h);

    //this.getList();
  },
  methods:{
    reset(){
      this.pageInfo.name=''
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getBuildingPage(this.pageInfo).then(res=>{

      })
    },
    closeDiv(){
      this.dialogFormVisible=false;
    },
    RoomChangeCss(p){
      let modeX=p%5;//求余 定 横坐标
      if(modeX==0){modeX=5;}
      let modeY=p/5;//求商 定 纵坐标
      modeY=Math.ceil(modeY);
      //此时的坐标第modeX行，第modeY列
      let moveX=141;
      if(modeY%2==0){moveX=282;}//奇数列191 偶数282
      let x=modeX*288+moveX-224;
      let y=modeY*80+(modeY-1)*48+100-(208-80);
      console.log("left:"+x+"px;top:"+y+"px;")
      return "left:"+x+"px;top:"+y+"px;"+"modeY="+modeY;
    }
  }
}
</script>

<style lang="scss" scoped>
.band-header{height: 100px;width: 100%;background-image: url("/gloabImg/header.png");background-size: 100%;background-repeat: no-repeat;text-align: center;
  color:#D8F0FF;font-size: 40px;
  div{padding-top: 12px;font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;}
}
.bgCss{background-image: url("/gloabImg/bgT.png");background-size: 100%;background-repeat: no-repeat;width: 100%;height:100vh;}
.ceneterTb{background-image: url("/gloabImg/centerTb.png");background-size: cover;background-repeat: no-repeat;width: 442px;height: 400px;position: fixed;
  left:667px;top:311px;}
  span{color: white;font-size: 36px;font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;padding-top: 20px}
.roomCss{height: 208px;width: 200px;position: fixed;z-index: 99999}
.roomUseText{position: relative;top: -110px;;border:2px solid rgba(0,255,255,0.4);background: rgba(0,0,0,0.6) rgba(0,255,255,0.1);
  box-shadow: inset 0px 0px 100px 0px rgba(0,255,255,0.12);width: 200px;height: 64px;color: white;text-align: left;font-size: 12px}
</style>