<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>
<script>
import { ElMessage,ElMessageBox,ElLoading } from 'element-plus';//全局导入ele 消息
import { useRouter,useRoute } from 'vue-router';//路由跳转
import {getMyMenu,isMobile,getCollegeAllList} from "@/api/api";
import { ElConfigProvider } from 'element-plus'
import moment from 'moment'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import {isExeMode, projectName} from "@/utils/config";
export default {
  components: {
    ElConfigProvider //展示中文
  },
  name: 'App',
  data(){//定义全局变量 其他组件使用 this.$root获取
    return{
      ElMessage:ElMessage,
      ElMessageBox:ElMessageBox,
      ElLoading:ElLoading,
      useRouter:useRouter(),
      useRoute:useRoute(),
      moment:moment,
      menuList:[],
      collegeList:[],
      projectName:projectName,
      examNameList: [ 'questionList', 'questionAdd', 'questionEdit', 'classification', 'examPaperList', 'examPaperAdd', 'examList', 'examResultList', 'examUserList', 'examCertificateList', 'resourceList'],
      stateList:['待审核','通过','不通过','退回','已撤销'],
      locale: zhCn
    }
  },
  mounted() {
    let eqment=this._isMobile();

    //
    if (window.location.hash) {
      const hashArr = window.location.hash.split('#')
      const hash = hashArr[hashArr.length - 1]
      if (hashArr.length && hash !== '/') {
        window.localStorage.setItem('menuUrl', hash)
      }
    }

    if(!eqment && localStorage.getItem('token')) {
      this.getMenuList();
    }
  },
  methods:{//定义全局方法 其他组件使用 this.$root获取
    _isMobile() {//判断当前设备是否是移动端
      return isMobile();
    },
    loadingOpen(){
      let loading=ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      return loading;
    },
    setRouter(){//动态添加路由
      for(let i in this.menuList){
        let firstM= this.menuList[i];
        if(firstM.url){
          let rout={path:firstM.url,component:()=>import('@/view'+firstM.url),name:firstM.name,meta: {title: firstM.title, noCache: true },children:[]};
          this.useRouter.addRoute('Home',rout);
        }else if(firstM.child.length>0){
          for (let j in firstM.child){
            let secondM=firstM.child[j];
            // 过滤大屏
            if(secondM.url && secondM.url.startsWith('/visualization')){
              continue
            }
            // 过滤
            if(secondM.url && secondM.url === '/monitor/monthlyOperation'){
              continue
            }
            if (this.examNameList.includes(secondM.name)) {
              continue
            }
            if(secondM.url){
              let rout={path:secondM.url,component:()=>import('@/view'+secondM.url),name:secondM.name,meta: {title: secondM.title, noCache: true },children:[]};
              this.useRouter.addRoute('Home',rout);
            }
          }
        }
      }
    },
    getMenuList(){
      getMyMenu().then(res=>{
        this.menuList=res.data;
        let url=this.getFirstMenuUrl();
        this.setRouter();//根据菜单注册组件
        setTimeout(()=>{
          if (!isExeMode()) {
            if (localStorage.getItem('menuUrl')) {
              this.$root.useRouter.push(localStorage.getItem('menuUrl'));
            } else {
              this.$root.useRouter.push(url);
            }
          }
        },500)
      })
    },
    getFirstMenuUrl(){
      let url='';
      for(let i in this.menuList){
        if(this.menuList[i].path){
          url=this.menuList[i].path;
          return url;
        }else{
          if(this.menuList[i].child.length>0){
            for(let j in this.menuList[i].child){
              if(this.menuList[i].child[j].url){
                return url;
              }
            }
          }
        }
        return url;
      }
    },
    isToday(time){//判断是不是当天
      let todayDate=moment(new Date()).format("YYYY-MM-DD");
      let checkDate=time.toString().slice(0, 10);
      if(todayDate==checkDate){return true;}else{return false;}
    },
    getCollegeList(){
      getCollegeAllList().then(res=>{
        this.collegeList=res.data;
      })
    }
  }
}
</script>

<style>
@import "static/css/all.css";
@import "static/css/mobile.css";
@import "static/font/siyuan.css";/* 引入思源黑体字体样式 https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900 */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*{margin:0;font-family: 'Noto Sans SC';font-weight: 400;}
.el-menu--collapse  .el-sub-menu__title .el-sub-menu__icon-arrow{
  display: none;
}
</style>
