<template>
  <div class="mobile-body" style="background: #F6F6F6;">
    <div class="monitor-video" v-loading="loadingPlayer">
      <div class="video" :id="cameraPlayer.elementId">
        <span style="line-height: 210px;">请选择摄像头</span>
      </div>
      <div class="left-top-name" v-if="cur">{{ cur.laboratoryName || '' }}</div>
      <div></div>
    </div>
    <div class="monitor-filter">
      <div class="search-div" style="position: relative;">
        <img src="@/static/img/admin/icon-search.png" alt="" class="icon-search">
        <el-input v-model="searchInput"
                  @input="remoteMethod"
                  placeholder="摄像头名称"
                  @focus="remoteMethod(searchInput)"
                  class="search-input"/>
        <div class="search-res" v-if="showSearchRes">
          <span class="el-popper__arrow"></span>
          <div class="item-box" v-if="cameraOptions.length">
            <div v-for="t in cameraOptions"
                 :key="'teacher-'+ t.id"
                 class="res-item"
                 @click.stop="searchChangeHandler(t)">
              {{ t.label }}
            </div>
          </div>
          <div v-else class="no-data">暂时没有数据</div>
        </div>
      </div>
      <div class="pl-8">
        <img src="@/static/img/admin/icon-filter.png" alt="" class="icon-filter"
             @click.stop="showVideoTypeDialog">
      </div>
    </div>
    <div class="monitor-building">
      <div class="building-item" v-for="building in dataList" :key="'building-'+building.id"
           @click="toggleFold(building)">
        <div class="building-div">
          <div class="flex align-center">
            <img src="@/static/img/admin/icon-building.png" alt="" class="building-icon">
            <span class="name">{{ building.name }}</span>
          </div>
          <img v-if="building.id == fold.building_id && fold.is_show" src="@/static/img/admin/icon-up.png"
               class="building-icon" alt="">
          <img v-else src="@/static/img/admin/icon-down.png" class="building-icon" alt="">
        </div>
        <div class="camera-div" v-if="fold.building_id == building.id && fold.is_show">
          <div class="camera-item flex align-center" v-for="item in building.equipments" :key="'equipment'+item.id"
               @click.stop="chooseCamera(building,item)">
            <img src="@/static/img/admin/icon-camera.png" alt="" class="camera-icon">
            <div class="camera-name" :class="cur&&cur.id == item.id ?'selected': '' ">{{ item.name }}</div>
          </div>
          <div class="no-data" v-if="building.equipments.length < 1">暂时没有摄像头</div>
        </div>
      </div>
    </div>
    <el-drawer v-model="showDialog" title="" :with-header="false" direction="btt"
               modal-class="bbt-modal-wrapper" :show-close="false"
               style="border-radius: 20px 20px 0 0;position: relative;">
      <div class="date-select-box" v-if="timeSelectShow">
        <van-datetime-picker v-model="timeSelectValue"
                             @confirm="confirmHandler"
                             @cancel="timeSelectShow = false"
                             type="datetime"
                             title="选择时间"/>
      </div>
      <div>
        <div class="title">
          <div class="qx" @click="showDialog = false">取消</div>
          <div class="name">播放类型</div>
          <div class="qd" @click="doConfirmMonitorType">确定</div>
        </div>
        <div class="content">
          <span class="type"
                v-for="(type,index) in monitorType"
                :key="index"
                :class="{'ml-8': index>0, selected: type.is_selected}"
                @click="chooseType(type)">{{ type.label }}</span>
          <div v-show="monitorType[1].is_selected">
            <div class="flex marginTop20px">
              <div>开始时间:</div>
              <div @click="selectTime('start')" class="date-time-show">
                {{ start || '选择回放开始时间' }}
              </div>
            </div>
            <div class="flex marginTop20px">
              <div>截止时间:</div>
              <div @click="selectTime('end')" class="date-time-show">
                {{ end || '选择回放截止时间' }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </el-drawer>
  </div>

</template>
<script>
import {getAllBuilding, monitorExistence} from "@/api/monitor";
import {
  MobileFlvPlayer as FlvPlayer,
  MobileFlvPlayerPlayback as PlaybackFlvPlayer
} from "@/utils/monitor";
import dayjs from "dayjs";

export default {
  data() {
    return {
      id: null,
      searchInput: '',
      showDialog: false,
      dataList: [],
      fold: {
        is_show: false,
        building_id: null,
      },
      cur: null,
      monitorType: [
        {label: '直播', value: 'zhibo', is_selected: true},
        {label: '回放', value: 'huofang', is_selected: false},
      ],
      // 正在播放的摄像头
      cameraPlayer: {},
      loadingPlayer: false,
      // 当前播放类型
      playMode: 'zhibo',
      start: '',
      end: '',
      //
      loading: false,
      cameraOptions: [
        // {
        //   label: '11',
        //   value: '11',
        // }
      ],
      showSearchRes: false,
      //
      timeSelectShow: false,
      timeSelectValue: new Date(),
      timeCurrKey: 'start',

    }
  },
  computed: {
    isDirectSeeding(){
      return this.playMode === 'zhibo'
    },
    isPlayback(){
      return this.playMode === 'huofang'
    }
  },
  watch: {
    showDialog: {
      handler(val){
        if (!val) {
          this.timeSelectShow = false
        }
      }
    }
  },
  mounted() {
    document.title = '视频监控'
    // 获取query
    const {query} = this.$route
    if (query) {
      this.id = query.id || null
      //
      this.destroyTheCamera()
      // 播放指定摄像头
      if (this.id) {
        this.playCamera(this.id)
      }
    }
    this.getList()
  },
  beforeUnmount(){
    this.destroyTheCamera()
  },
  methods: {
    selectTime(key){
      this.timeCurrKey = key
      this.timeSelectShow = true
    },
    confirmHandler(val){
      this[this.timeCurrKey] = dayjs(val).format("YYYY-MM-DD HH:mm:ss").toString()
      this.timeSelectShow = false
    },

    getList() {
      getAllBuilding().then(res => {
        let listData = []
        const buildings = res.data || []
        for (let building of buildings) {
          let equipments = []
          if (!building.laboratoryList || building.laboratoryList.length < 1) continue;
          for (let lab of building.laboratoryList) {
            if (!lab.equipmentList || lab.equipmentList.length < 1) continue;
            for (let equ of lab.equipmentList) {
              let equipment = Object.assign(equ, {
                buildingId: building.id,
                buildingName: building.name,
                laboratoryId: lab.id,
                laboratoryName: lab.name
              })
              equipments.push(equipment)
              if (this.id == equipment.id) {
                this.cur = equipment
                this.fold = {
                  building_id: equipment.buildingId,
                  is_show: true
                }
              }
            }
          }
          listData.push({
            id: building.id,
            name: building.name,
            code: building.code,
            equipments: equipments,
          })
        }
        this.dataList = listData
        //
        this.remoteMethodById(this.id)
      })
    },
    chooseCamera(building, item) {
      // 关闭销毁当前摄像头
      this.destroyTheCamera()
      // -
      this.fold = {
        is_show: true,
        building_id: building.id,
      }
      this.cur = item

      // 直播
      if (this.isDirectSeeding) {
        // 开启新的摄像头
        this.playCamera(item.id)
        return
      }
      // 回放
      if (this.isPlayback) {
        // 开启新的摄像头 回放
        this.chooseDateFiltering()
      }
    },
    // 收起/展开
    toggleFold(building) {
      if (this.fold.building_id !== building.id) {
        this.fold.building_id = building.id
        this.fold.is_show = true
      } else {
        this.fold.building_id = building.id
        this.fold.is_show = !this.fold.is_show
      }
    },
    //
    showVideoTypeDialog() {
      this.showDialog = true
    },
    // 搜索
    doSearch() {
      for (let building of this.dataList) {
        if (building.equipments.length) {
          for (let item of building.equipments) {
            if (item.name.includes(this.searchInput)) {
              this.fold = {
                building_id: building.id,
                is_show: true
              }
              this.cur = item
              break
            }
          }
        }
      }
      if(!this.cur) {
        this.$message.error('未搜索到摄像头')
      }
    },
    remoteMethodById(id){
      this.cameraOptions = []
      for (let building of this.dataList) {
        if (building.equipments.length) {
          for (let item of building.equipments) {
            if (String(item.id) === String(id)) {
              this.cameraOptions.push({
                label: item.name,
                value: item,
                building_id: building.id,
              })

              this.fold = {
                building_id: building.id,
                is_show: true
              }

              this.cur = item

              return
            }
          }
        }
      }
    },
    /**
     * 搜索摄像头
     */
    remoteMethod(val){
      if (!val) {
        this.showSearchRes = false
        return
      }

      this.loading = true
      this.cameraOptions = []
      let count = 0
      for (let building of this.dataList) {
        if (building.equipments.length) {
          for (let item of building.equipments) {
            if (item.name.includes(val)) {
              if (count < 10) {
                this.cameraOptions.push({
                  label: item.name,
                  value: item,
                  building_id: building.id,
                })
              }
              count++
            }
          }
        }
      }
      this.loading = false
      this.showSearchRes = true
    },
    searchChangeHandler(val){
      this.showSearchRes = false
      this.fold = {
        building_id: val.building_id,
        is_show: true
      }
      this.cur = val.value
      // 播放
      this.chooseCamera({ id: val.building_id }, val.value)
    },
    // 播放类型
    chooseType(type) {
      this.monitorType.map(item => {
        item.is_selected = type.value == item.value
      })
    },
    doConfirmMonitorType() {
      const res = this.monitorType.find(item => item.is_selected)
      if (!res) return this.$message.error('请选择播放类型')

      // 回放模式检测
      if (res.value === 'huofang') {
        if (!this.start) {
          return this.$message.error('请选择回放开始时间')
        }
        if (!this.end) {
          return this.$message.error('请选择回放截止时间')
        }
      }

      //
      if (this.playMode !== res.value) {
        this.destroyTheCamera()
      }

      // 回放切直播
      let playbackToDirectSeeding = () => {}
      if (this.isPlayback) {
        playbackToDirectSeeding = () => {
          //
          const id = this?.cur?.id || null
          id && this.playCamera(id)
        }
      }

      // 标记播放类型 -
      this.playMode = res.value
      this.showDialog = false

      // 如果是回放, 重新播放当前摄像头
      if (this.isPlayback) {
        // 关闭销毁当前摄像头
        this.destroyTheCamera()
        // 重新播放当前摄像头
        this.chooseDateFiltering()
      } else {
        playbackToDirectSeeding()
      }
    },
    // -
    loadingHandler(handler){
      // loading start
      this.loadingPlayer = true

      handler()

      // loading end
      setTimeout(() => {
        this.loadingPlayer = false
      }, 500)
    },
    /**
     * 播放摄像头
     */
    playCamera(id){
      this.loadingHandler(() => {
        // 开启新的摄像头
        this.cameraPlayer = new FlvPlayer(id, 'cameraPlayer')
        this.$nextTick(() => this.cameraPlayer.play())
      })
    },
    /**
     * 播放回放摄像头
     */
    playBackHandler(id){
      // 播放回放
      const playbackHandler = () => {
        this.loadingHandler(() => {
          // 开启新的摄像头
          this.cameraPlayer = new PlaybackFlvPlayer(id, 'cameraPlayer', this.start, this.end)
          this.$nextTick(() => this.cameraPlayer.play())
        })
      }

      monitorExistence(id, {
        startDate: this.start,
        endTime: this.end,
      }).then(res => {
        if (res.success) {
          playbackHandler()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 销毁摄像头
     */
    destroyTheCamera(){
      if (this.cameraPlayer.flvPlayer) {
        this.cameraPlayer.destruction()
      }
      this.cameraPlayer = {}
    },
    // - e
    /**
     * 选择回放时间
     */
    chooseDateFiltering(){
      // 回放摄像头
      this.playBackHandler(this.cur.id)
    },
  }
}

</script>

<style scoped lang="scss">
/deep/ .custom-select{
  .el-input__wrapper {
    background-color: transparent;
    box-shadow: unset;
  }
  --el-select-input-focus-border-color: unset;
}

.date-time-show{
  font-size: 14px;
  padding: 4px 8px;
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
}

.date-select-box{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.search-res {
  position: absolute;
  top: 52px;
  width: 95%;
  left: 0;
  z-index: 50;
  background: #ffffff;
  text-align: left;
  padding: 0 10px;
  border-radius: 6px;
  box-shadow: var(--el-box-shadow-light);
  border: 1px solid var(--el-border-color-light);

  /deep/ .el-popper__arrow {
    position: absolute;
    top: -3px;
    right: 30px;
    z-index: 30;
  }

  /deep/ .el-popper__arrow::before {
    background: #fff;
  }

  .item-box {
    min-height: 100px;
    max-height: 170px;
    overflow-y: scroll;
    padding: 0 10px;

    .res-item {
      line-height: 34px;
      height: 34px;
      font-size: 14px;
      color: #606266;
    }
  }

  .no-data {
    padding: 20px 10px;
    text-align: center;
    font-size: 14px;
    color: #999;
  }
}

.monitor-video {
  width: 100vw;
  height: 210px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  .video {
    width: 100%;
    height: 210px;
    background-color: #00000a;
    color: #ffffff;
  }

  .left-top-name {
    position: absolute;
    left: 12px;
    top: 12px;
    color: #fff;
    font-size: 13px;
  }
}

.monitor-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: #ffffff;


  .search-div {
    flex: 1;
    background: #F6F6F6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 11px 12px;
    height: 38px;
    box-sizing: border-box;

    .icon-search {
      width: 16px;
      height: 16px;
    }

    .search-input {
      width: 100%;

      ::v-deep .el-input__wrapper {
        background: #F6F6F6;
        box-shadow: unset;
      }
    }

  }

  .pl-8 {
    padding-left: 8px;
  }

  .icon-filter {
    width: 24px;
    height: 24px;
  }
}

.monitor-building {
  padding-bottom: 34px;

  .building-item {
    margin-top: 8px;

    .building-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      padding: 15px 16px;

      .name {
        padding-left: 8px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }

      .building-icon {
        width: 24px;
        height: 24px;
      }
    }

    .camera-div {
      padding: 8px 0;
      background: #F6F6F6;

      .camera-item {
        padding: 9px 32px;
        margin-top: 8px;

        &:first-child {
          margin-top: 0;
        }

        .camera-icon {
          width: 16px;
          height: 16px;
        }

        .camera-name {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 8px;
          text-align: left;

          &.selected {
            color: #4A72FF;
          }
        }
      }


    }
  }
}

::v-deep .el-drawer {
  border-radius: 20px 20px 0 0 !important;
  height: 40%!important;
}

.bbt-modal-wrapper {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;


    .qx {
      font-size: 16px;
      color: #999999;
    }

    .name {
      font-size: 17px;
      font-weight: 600;
      color: #333333;
    }

    .qd {
      font-size: 16px;
      color: #4A72FF;
    }

  }

  .content {
    padding: 32px 0;
    text-align: left;

    .type {
      display: inline-block;
      width: 80px;
      height: 36px;
      line-height: 36px;
      background: #F6F6F6;
      border-radius: 4px;
      text-align: center;
      color: #333;

      &.selected {
        background: #4A72FF;
        color: #ffffff;
      }

      &.ml-8 {
        margin-left: 8px;
      }
    }

  }
}


</style>
