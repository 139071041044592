<template>
  <div class="all-background-color">
    <el-header class="the-background-color all-font-active-color toolbar">
      <div class="flex justify-between align-center">
        <div class="flex align-end" style="margin-top: 13px">
          <img src="@/static/img/logo.png" style="height: 30px">
          <span style="margin-left: 16px">实验室综合管理平台</span>
        </div>
        <div style="font-size: 14px;cursor: pointer;margin-top: 13px">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{admin.name}}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
<!--                <el-dropdown-item>个人中心</el-dropdown-item>-->
                <el-dropdown-item @click="changePass">修改密码</el-dropdown-item>
                <el-dropdown-item divided @click.stop="loginOut">退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </el-header>
    <el-container class="layout-container-demo" :style="'height:'+(clientHeight-60)+'px'">
      <div style="background-color: #FFFFFF;box-shadow: 2px 0px 8px 0px rgba(0,0,0,0.08);">
        <el-col style="width: 100%;">
          <el-menu :default-active="String(menuIndex)" :unique-opened="true" class="el-menu-vertical-demo" popper-effect="light" :collapse="!isZhankai" active-text-color="#3769B9" background-color="#FFFFFF" text-color="#333333">
            <div v-for="(l,index) in menuList" :key="index">
              <div v-if="l.child.length>0">
                <el-sub-menu :index="String(index)">
                  <template #title>
                      <component :is="l.icon" class="icon-size20"></component>
                      <span v-if="isZhankai" @click="goTo(index,l.url,l.title,'', l.open)">{{l.title}}</span>
                  </template>
                  <el-menu-item-group>
                    <!--      第一分组        <template #title>Group 1</template>-->
                    <el-menu-item v-for="(c,index1) in l.child" :index="index+'-'+index1" :key="index1"
                                  @click="goTo(index+'-'+index1,c.url,l.title,c.title, c.open)">
                      {{c.title}}
                    </el-menu-item>
                  </el-menu-item-group>
                </el-sub-menu>
              </div>
              <div v-else>
                <el-menu-item :index="String(index)" @click="goTo(index,l.url,l.title,'', l.open)">
                  <component :is="l.icon" class="icon-size20"></component>
                  <span v-if="isZhankai">{{l.title}}</span>
                </el-menu-item>
              </div>
            </div>
          </el-menu>
        </el-col>
      </div>
<!-- <el-icon><Fold /></el-icon>   <el-icon><Expand /></el-icon> -->
      <div style="position: fixed;bottom: 10px;left: 170px;font-size: 24px" v-if="isZhankai">
        <el-icon @click="zhankai"><Fold /></el-icon>
      </div>
      <div style="position: fixed;bottom: 10px;left: 15px;font-size: 24px" v-else>
        <el-icon @click="zhankai"><Expand /></el-icon>
      </div>

      <el-container>
        <el-main style="background-color: #F7F9FC;">
          <div style="font-size: 12px;text-align: left;padding:13px 36px">
            <span style="color:rgba(0,0,0,0.58)">{{title1}}</span><span v-if="title2"> / {{title2}}</span>
          </div>
          <div style="margin:0px 24px 33px 24px;">
            <router-view></router-view>
          </div>
        </el-main>
<!--        <el-footer style="background-color: #15161A;margin-left: -2px"></el-footer>-->
      </el-container>
    </el-container>
    <el-dialog title="修改密码" class="info-div600" v-model="passShow" style="padding: 24px;text-align: left">
      <el-form label-width="80px">
        <el-form-item label="原密码">
          <el-input v-model="passInfo.oldPassword" autocomplete="off" type="password" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="passInfo.newPassword" autocomplete="off" type="password" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input v-model="passInfo.twoNewPassword" autocomplete="off" type="password" placeholder="请输入"/>
        </el-form-item>
      </el-form>
      <div class="dialog-footer marginTop30px text-right">
        <button class="subBtn" @click="subData">提交</button>
        <button class="qxBtn" @click="closeDiv">取消</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getMyMenu,loginOut,getAdminInfo,changePass} from "@/api/api.js"
export default {
  data(){
    return{
      menuList:[],
      menuIndex:localStorage.getItem('menuIndex') || '0-0',
      title1:localStorage.getItem('title1'),
      title2:localStorage.getItem('title2'),
      clientHeight:`${document.documentElement.clientHeight}`,
      admin:[],
      isZhankai:true,
      passShow:false,
      passInfo:{
        oldPassword:'',
        newPassword:'',
        twoNewPassword:''
      }
    }
  },
  created() {
    this.$root.getCollegeList();
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    let cwidth=`${document.documentElement.clientWidth}`;
    if(cwidth<1200){
      this.isZhankai=false;
    }
    this.getAdmin();
    this.getMenuList();
    setTimeout(()=>{
      this.menuList=this.$root.menuList;
    },200)
  },
  methods:{
    goTo(index,url,title1,title2,open){
      if(url){
        window.localStorage.setItem('menuIndex',index);
        window.localStorage.setItem('menuUrl',url);
        window.localStorage.setItem('title1',title1);
        window.localStorage.setItem('title2',title2);
        if (url && open) {
          window.open(`/#${url}`, '_blank')
          return
        }
        this.title1=title1;this.title2=title2;
        this.$root.useRouter.push(url);
      }
    },
    changePass(){
      this.passShow=true;
    },
    closeDiv(){
      this.passShow=false;
    },
    subData(){
      if(!this.passInfo.oldPassword || !this.passInfo.newPassword || !this.passInfo.twoNewPassword){this.$root.ElMessage.error('密码不能为空');return false;}
      if(this.passInfo.newPassword!=this.passInfo.twoNewPassword){this.$root.ElMessage.error('两次密码不一致');return false;}
      changePass(this.passInfo).then((res)=>{
        if(res.success){
          this.$root.ElMessage({type:'success',message:'密码修改成功，请重新登录！'});
          loginOut();
          this.$root.useRouter.push('/');
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    zhankai(){
      this.isZhankai=!this.isZhankai;
      this.$forceUpdate();
    },
    loginOut(){
      this.$root.ElMessageBox.confirm(
          '你确定要退出吗?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
          }
      ).then(() => {
        loginOut();
        this.$root.useRouter.push('/');
      })
    },
    getAdmin(){
      getAdminInfo().then(res=>{
        this.admin=res.data.userInfo;
        window.localStorage.setItem('admin',res.data.userInfo);
      })
    },
    getMenuList(){
      getMyMenu().then(res=>{
        this.menuList=res.data;
        this.$root.menuList=res.data;
      })
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.layout-container-demo .el-header {
  position: relative;
  background-color: var(--el-color-primary-light-7);
  color: var(--el-text-color-primary);
}
.layout-container-demo .el-aside {
  color: var(--el-text-color-primary);
  background: white;
}
.layout-container-demo .el-menu {
  border-right: none;
}
.layout-container-demo .el-main {
  padding: 0;
}
.all-background-color .toolbar {
  font-size: 20px;box-shadow: 2px 2px 8px 0px rgba(82,90,102,0.08), 1px 1px 2px 0px rgba(82,90,102,0.04);border-bottom: 1px solid #EBEBEB;
}
.el-container.is-vertical{
  margin-left: 1px;
}
router-link{
  text-decoration: none;
}
</style>
