<template>
  <div class="mobile-body" style="padding-bottom: 70px">
    <div style="padding: 16px 16px 0 16px;background-color: white">
      <div class="flex">
        <div><img :src="Info.img?projectName+Info.img:defaultRoomImg" class="img86"></div>
        <div class="marginLeft10 text-left">
          <div class="fontSize15gayB">{{Info.name}}</div>
          <div class="marginTop20px fontSize14gay99 flex align-center"><img src="@/static/img/mobile/room/data.png" /><span>已使用课程：{{Info.useSize}}</span></div>
          <div class="fontSize14gay99 flex align-center marginTop5"><img src="@/static/img/mobile/room/book.png" /><span>使用率：{{Info.usageTate}}%</span></div>
        </div>
      </div>
      <div class="flex marginTop20px">
        <div v-for="(s,index) in stepNum" :key="index" :class="s.no==num?'fontSize16BB':'fontSize16gay99'" style="margin-right: 30px" @click="changeStep(s.no)">
          <div>{{s.name}}</div>
          <div class="step-bottom-div" :class="s.no==num?'step-bottom-check':''"></div>
        </div>
      </div>
    </div>
    <div v-if="num==0">
      <div class="text-left ceng-box-rop">
        <div class="flex align-center"><div class="step-left-div"></div><div class="fontSize15Black marginLeft5">基本信息</div></div>
        <div class="flex marginTop25px"><div class="fontSize14gay66 width80">名称</div><div class="fontSize14gay">{{Info.name}}</div></div>
        <div class="flex marginTop15px"><div class="fontSize14gay66 width80">楼栋</div><div class="fontSize14gay">{{Info.building?Info.building.name:''}}</div></div>
        <div class="flex marginTop15px"><div class="fontSize14gay66 width80">管理员</div><div class="fontSize14gay">{{Info.userNames}}</div></div>
        <div class="flex marginTop15px"><div class="fontSize14gay66 width80">管理员电话</div><div class="fontSize14gay">{{Info.userPhone}}</div></div>
      </div>
      <div class="text-left ceng-box-rop">
        <div class="flex align-center"><div class="step-left-div"></div><div class="fontSize15Black marginLeft5">预约规则</div></div>
        <div class="flex marginTop25px"><div class="fontSize14gay66 width80">提前预约</div><div class="fontSize14gay">须提前 {{Info.openBeforeDayMin}}~{{Info.openBeforeDayMax}} 天预约</div></div>
        <div class="flex marginTop25px"><div class="fontSize14gay66 width80">可预约时段</div><div class="fontSize14gay">{{Info.openLessonNames}}</div></div>
        <div class="flex marginTop25px"><div class="fontSize14gay66 width80">时长限制</div><div class="fontSize14gay">同一天学生最多可预约{{Info.studentDayMax}}节课</div></div>
        <div class="flex marginTop15px"><div class="fontSize14gay66 width80">是否开放</div><div class="fontSize14gay">{{Info.open?'是':'否'}}</div></div>
        <div class="flex marginTop15px"><div class="fontSize14gay66 width80">对学生开放</div><div class="fontSize14gay">{{Info.openStudent?'是':'否'}}</div></div>
      </div>
      <div class="mobile-sub-div">
        <div class="mobile-sub-btn" @click="changeStep(1)">开始预约</div>
      </div>
    </div>
    <div v-if="num==1">
      <div class="flex align-start marginTop8px" style="background-color: white;padding: 12px">
        <div style="margin-bottom: 20px">
          <div class="flex fontSize14gay justify-center">
            <div class="flex align-center"><div class="box-tip" style="background-color: #F2F5FF;"></div><div>可预约</div></div>
            <div class="flex align-center"><div class="box-tip" style="background-color: #F0F0F0;"></div><div>不可预约</div></div>
            <div class="flex align-center"><div class="box-tip" style="background-color: #4A72FF;"></div><div>已选</div></div>
          </div>
          <div class="flex marginTop10px">
            <div>
              <div class="title-appoin"><div>日期</div><div>时间段</div></div>
              <div v-for="(c,index) in courseList" :key="index" class="fontSize12gay66 Time-oclp">
                <div>{{c.start}}</div><div>{{c.end}}</div>
              </div>
            </div>
            <div style="width: 330px;overflow-x: scroll;">
                <div class="flex">
                  <div v-for="(cda,index) in apppointList" :key="index" style="margin-left: 8px">
                    <div class="title-appoin">{{cda.date}}</div>
                    <div v-for="(cour,index1) in cda.curriculumList" :key="index1" class="Course-oclp" @click="setCourse(cour.id,cour.isAppoint,cda.date)" :class="cour.isAppoint?(rpoo(cour.id,cda.date)?'course-used-check':'course-used-allow'):'course-used-no'">
                      {{cour.name}}
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-sub-div">
        <div class="mobile-sub-btn" @click="changeStep(2)">下一步</div>
      </div>
    </div>

    <div v-if="num==2">
      <div style="width: 100%;background-color: white;margin-top: 8px">
        <div style="padding: 16px">
          <div class="fontSize16gay text-left">申请材料<span class="fontSize14gay marginLeft5" style="color: red">(如无需提前准备好的可不填)</span></div>
          <div>
            <div v-for="(k,index) in assetCheckList" class="flex justify-between align-center" :key="index" style="padding: 6px 12px;border: 1px solid #EBEBEB;border-radius: 4px;margin-top: 12px">
              <div class="fontSize14gay">{{k.name}}</div>
              <div class="flex align-center">
                <span class="fontSize14gay99">数量：</span><input type="number" v-model="assetCheckList[index].checkNumber" class="asset-number-input">
                <img src="@/static/img/mobile/room/x.png" class="img24" @click="delAsset(k.id)">
              </div>
            </div>
          </div>
          <div class="flex justify-center align-center addAsset" @click="addAssetMore">
            <span style="font-size: 22px">+</span><span class="marginLeft5">添加材料</span>
          </div>
        </div>
      </div>

      <div style="width: 100%;background-color: white;margin-top: 8px">
        <div style="padding: 16px">
          <div class="fontSize16gay text-left">用途说明</div>
          <div class="marginTop15px">
            <el-input v-model="appiontmentInfo.use" placeholder="请填写用途说明"></el-input>
          </div>
        </div>
        <div style="padding: 0 16px 16px;">
          <div class="fontSize16gay text-left">授课班级</div>
          <div class="marginTop15px">
            <el-input v-model="appiontmentInfo.className" placeholder="请填写授课班级" :maxlength="255"></el-input>
          </div>
        </div>
        <div style="padding: 0 16px 16px;" :class="{'is-student': isStudent}">
          <div class="fontSize16gay text-left is-student-label">学生人数</div>
          <div class="marginTop15px">
            <el-input type="number" v-model="appiontmentInfo.studentNum" placeholder="请填写学生人数"></el-input>
          </div>
        </div>
        <div style="padding: 0 16px 16px;">
          <div class="fontSize16gay text-left">联系电话</div>
          <div class="marginTop15px">
            <el-input type="number" v-model="appiontmentInfo.studentPhone" placeholder="请填写联系电话"></el-input>
          </div>
        </div>
        <div style="padding: 0 16px 16px;">
          <div class="fontSize16gay text-left">附件</div>
          <div class="marginTop15px">
            <el-upload v-show="fileList.length === 0"
                       class="upload-demo"
                       action=""
                       ref="filesUpload"
                       list-type="picture"
                       :multiple="true"
                       :show-file-list="false"
                       :limit="5"
                       :file-list="fileList"
                       :http-request="handlerUpload" >
              <el-button style="border: none;background-color: #E6EBF0;">
                上传附件<el-icon class="el-icon--right"><Upload /></el-icon>
              </el-button>
            </el-upload>
            <div class="common-file-list-container"
                 v-for="(iter, index) in fileList" :key="iter.name">
              <div class="common-file-list-item-left">
                <div style="display: flex;flex: 1;">
                  <div>
                    <el-icon><DocumentRemove /></el-icon>
                  </div>
                  <div style="margin-left: 14px;flex: 1;font-size: 14px;">{{ iter.name }}</div>
                </div>
              </div>
              <div class="common-file-list-item-right">
                <el-icon @click="removeHandler(index)"><DeleteFilled /></el-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-sub-div flex justify-between">
        <div class="mobile-pre-btn" style="width: 36%;margin-right: 0" @click="changeStep(1)">上一步</div>
        <div class="mobile-sub-btn" style="width: 60%;margin-left: 12px" @click="subData">提交预约</div>
      </div>
      <div class="mobile-div-black" v-show="showAsset"></div>
      <div class="mobile-div-show" v-show="showAsset">
        <div style="padding: 16px">
          <div class="flex justify-between">
            <span class="fontSize16gay99" @click="qxCloseAsset">取消</span>
            <span class="fontSize17gayBB">请选择材料</span>
            <span class="fontSize16gay99" style="color: #4A72FF" @click="qxCloseAsset">确认</span>
          </div>
          <div class="flex-wrap marginTop8px">
            <div v-for="(a,index) in assetList" :key="index" class="marginTop8px assetChooseDiv" :class="assetIds.includes(a.id)?'assetChooseDiv-check':''" @click="chooseAsset(index)">
              <span class="fontSize14gay">{{a.name}}</span><span class="fontSize12gay tl-check">({{a.number}})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getRoomInfo, getUserInfo} from "@/api/mobile";
import {projectName} from "@/utils/config"
import {getAppointCourseInfo, getCourseList, getAssetListAll, addAppointment, uploadFile} from "@/api/api";
export default {
  data(){
    return{
      projectName:projectName,
      defaultRoomImg:require('@/static/img/mobile/index/hot-room.png'),
      Info:{id:''},
      appiontmentInfo:{
        id:'',
        curriculumId:'',
        date:'',
        laboratoryId:'',
        use:'',
        utensil:[],
        className: '',
        studentNum: '',
        studentPhone: '',
      },
      checkDateCurrArray:[],
      stepNum:[{no:0,name:'实验室信息'},{no:1,name:'预约时段'},{no:2,name:'使用材料'}],
      num:0,
      apppointList:[],
      courseList:[],
      courseWidth:'375px',
      assetList:[],
      assetIds:[],
      assetCheckList:[],
      showAsset:false,
      isStudent: false,
      //
      fileList: [],
    }
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    let clientWidth = `${document.documentElement.clientWidth}`;
    this.courseWidth=clientWidth-100;
    console.log(this.courseWidth);
    this.Info.id=this.$root.useRoute.query.id;
    if(this.Info.id) {
      this.getInfo();
      this.getAppointCourseInfo();
    }

    // 学生预约
    getUserInfo().then(res => {
      if (res.success) {
        this.isStudent = res.data.userType === 'student'
      }
    })
  },
  methods:{
    /**
     * 自定义文件上传
     */
    handlerUpload(e){
      const loading = this.$loading({ fullscreen: true })
      const formData = new FormData()
      formData.append('file', e.file)
      uploadFile(formData).then(res => {
        if (res.success) {
          const name = ((res.data || '').split('/') || []).reverse()[0]
          this.fileList.push({
            name: name,
            url: res.data,
            value: res.data,
          })
        } else {
          this.$message.error(res.message)
        }
      }).finally(() => {
        loading.close()
      })
    },
    removeHandler(index){
      this.fileList.splice(index, 1)
    },
    getInfo(){//获取须知
      getRoomInfo(this.Info.id).then(res=>{
        this.Info=res.data;
      })
    },
    changeStep(no){
      this.num=no;
    },
    setCourse(id,flag,date){
      if(flag){
        let f=0;
        let k=date;
        this.checkDateCurrArray.find((res,i)=>{
          if(res.date==k && res.curriculumId==id){
            this.checkDateCurrArray.splice(i,1);
            f++;
            return;
          }
        })
        if(f==0){this.checkDateCurrArray.push({date:k,curriculumId:id})}
      }
    },
    rpoo(id,data){//判断数组是否包含
      let f=0;
      this.checkDateCurrArray.find(res=>{
        if(res.date==data && res.curriculumId==id){
          f++;
          return;
        }
      })
      if(f>0){return 1}else{return 0;}
    },
    getAppointCourseInfo(){
      getAppointCourseInfo(this.Info.id).then((res)=>{
        this.apppointList=res.data;
      })
      getCourseList().then((res)=>{
        this.courseList=res.data;
      })
      getAssetListAll(this.Info.id).then((res)=>{
        this.assetList=res.data;
      })
    },
    chooseAsset(index){
      let aslist=this.assetList[index];
      if(this.assetIds.includes(aslist.id)){
        this.assetIds.splice(this.assetIds.indexOf(aslist.id),1);
        this.assetCheckList.find((res,i)=>{
          if(res.id==aslist.id){
            this.assetCheckList.splice(i,1);
          }
        })
      }else{
        this.assetIds.push(aslist.id);
        aslist.checkNumber=0;
        this.assetCheckList.push(aslist);
      }
    },
    delAsset(id){
      this.assetIds.splice(this.assetIds.indexOf(id),1);
      this.assetCheckList.find((res,i)=>{
        if(res.id==id){
          this.assetCheckList.splice(i,1);
        }
      })
    },
    addAssetMore(){
      this.showAsset=true;
    },
    qxCloseAsset(){
      this.showAsset=false;
    },
    subData(){
      if (this.isStudent) {
        if (!this.appiontmentInfo.studentPhone) {
          this.$root.ElMessage.error({message: '请填写联系电话'})
          return
        }
      }

      // 必填
      if (!this.appiontmentInfo.use) {
        this.$message.error('请填写用途说明')
        return
      }

      let dates='';
      let curriculumIds='';
      for(let j in this.checkDateCurrArray){
        let s=this.checkDateCurrArray[j];
        if(j==0){dates=s.date;curriculumIds=s.curriculumId;}else{dates=dates+','+s.date;curriculumIds=curriculumIds+','+s.curriculumId}
      }
      this.appiontmentInfo.date=dates;
      this.appiontmentInfo.curriculumId=curriculumIds;
      if(!this.appiontmentInfo.curriculumId || !this.appiontmentInfo.date){
        this.$root.ElMessage.error({message: '请选择预约时段'});
        return false;
      }
      this.appiontmentInfo.laboratoryId=this.Info.id;
      let kc=[];
      for(let i in this.assetCheckList){
        let s=this.assetCheckList[i];
        kc.push({number:s.checkNumber,utensilId:s.id});
      }
      this.appiontmentInfo.utensil=kc;
      let method='post';
      //if(this.Info.id){method='put';}

      // 附件
      if (this.fileList.length > 0) {
        this.appiontmentInfo.annex = this.fileList[0].url
      }

      addAppointment(this.appiontmentInfo,method).then((res)=>{
        if(res.success){
          this.$root.ElMessage({type:'success',message:res.message});
          this.$root.useRouter.push("/mobile/room/myYuYue");
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.is-student > .is-student-label{
  &::before{
    margin-right: 2px;
    content: '*';
    color: #ff0000;
    font-weight: bold;
  }
}

.upload-demo{
  display: flex;
}
.common-file-list-container{
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  .common-file-list-item-left{
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #F5F7FA;
    border-radius: 2px;
    font-size: 16px;
  }
  .common-file-list-item-right{
    margin-left: 14px;
    font-size: 16px;
    color: #8D9094;
  }
}
  .mobile-login-input{
    background:#F6F6F6;height: 44px;border-radius:4px;height: 44px;display: flex;align-items: center;padding: 10px 16px;margin: auto 16px;margin-top: 20px;
    image{width: 24px;height: 24px}
    input{margin-left: 10px;width: 95%;outline: none;background: transparent;height: 28px;border: 0;font-size: 15px}
  }

  .title-appoin{color:#333333;font-size: 13px;width: 52px;height: 52px}
  .Time-oclp{padding:6px 12px;height: 32px;width: 32px;margin-bottom: 8px;font-size: 13px}
  .Course-oclp{height: 44px;line-height: 42px;width: 56px;font-size: 13px;margin-bottom: 8px}
  .course-used-allow{background: #EBF0F8;color: #4A72FF;cursor: pointer;}
  .course-used-no{background: #F0F0F0;color: #666666;cursor: not-allowed}
  .course-used-check{background: #4A72FF !important;color: #ffffff !important;cursor: default;}
  .box-tip{height: 12px;width: 12px;border-radius: 2px;margin: 0 10px 0 20px}
  .my-form-title{width: 108px;text-align: right;color: #606266;font-size: 14px;padding-right: 12px}

  .addAsset{background-color: #F6F6F6;margin: 12px 0px;color: #4A72FF;font-size: 16px;font-weight: 500;padding: 11px 0;border-radius: 4px}
  .asset-number-input{border-radius: 4px;border:1px solid #EBEBEB;width: 55px;height: 30px;text-align: center;color: #333333;font-size: 14px;margin: 0 8px;outline: none}
  .assetChooseDiv{margin-right: 6px;padding: 6px 8px;background-color: #F6F6F6;border-radius: 4px;margin-top: 8px}
  .assetChooseDiv-check{background-color: #4A72FF !important;}
  .assetChooseDiv-check span{color: white !important;}
  .mobile-div-show{background-color: white;position: fixed;z-index: 9999;bottom: 0;left: 0;width: 100%;border-radius: 20px 20px 0px 0px;min-height: 150px}
  .mobile-div-black{width: 100%;height: 100vh;position: fixed;z-index: 9998;background-color: black;opacity: 0.5;top:0;left: 0}
</style>