export const menus=[
    {
        id:1,
        parent_id:null,
        title:'首页',
        name:'House',
        icon:'House',
        url:'/index',
        childrens:[]
    },
    {
        id:2,
        parent_id:null,
        title:'角色管理',
        name:'User',
        icon:'User',
        url:'',
        childrens:[
            {id:3, title:'开发商管理',name:'devList',parent_id:2, url:'/devManage/devList',childrens:[]},
            {id:4, title:'经销商管理',name:'selList',parent_id:2, url:'/person/selList',childrens:[]},
            {id:5, title:'农户管理',name:'nohList',parent_id:2, url:'/person/nohList',childrens:[]},
        ]
    },
    {
        id:6,
        parent_id:null,
        title:'划转管理',
        name:'Compass',
        icon:'Compass',
        url:'',
        childrens:[
            {id:7, title:'租金配置',name:'rentList',parent_id:6, url:'/person/devList',childrens:[]},
            {id:8, title:'类型列表',name:'rovList',parent_id:6, url:'/person/selList',childrens:[]},
        ]
    },
    {
        id:9,
        parent_id:null,
        title:'系统管理',
        name:'Setting',
        icon:'Setting',
        url:'',
        childrens:[
            {id:10, title:'菜单管理',name:'menuList',parent_id:9, url:'/person/devList',childrens:[]},
            {id:11, title:'全局配置',name:'allSet',parent_id:9, url:'/person/selList',childrens:[]},
        ]
    }
]

/**
 * 是否是EXE
 * @returns {boolean}
 */
export const isExeMode = () => process.env.VUE_APP_IS_EXE === 'T'
// 本地调试
export const isDev = () => process.env.VUE_APP_DEBUG_ENV === 'dev'
export const isTest = () => process.env.VUE_APP_DEBUG_ENV === 'test'

class Config{
    constructor() {
        // projectName, wsUrl, messageWs
        // -
        // 开发
        if (isDev()) {
            this.devHost = '192.168.1.202:9998'
            // this.devHost = 'lab.guc.edu.cn/api'
        }
        // 测试
        if (isTest()) {
            this.devHost = 'lab.it9g.com/api'
        }
        // 生产
        this.socket_process = window.location.protocol === 'http:' ? 'ws://' : 'wss://'
    }

    /**
     * exe打包时用到的
     */
    getExeConfig(){
        if (process.env.VUE_APP_EXE_ENV === 'dev') {
            // 开发
            // ...
            // 测试
            return {
                // 调试测试
                url: 'lab.it9g.com/api',
                // 调试正式
                // url: 'lab.guc.edu.cn/api',
                // -
                socket_process: 'wss://'
            }
        } else {
            return {
                url: 'lab.guc.edu.cn/api',
                socket_process: 'wss://'
            }
        }
    }

    getMessageWs(){
        if (isExeMode()) {
            const { socket_process, url } = this.getExeConfig()
            return token => `${socket_process}${url}/websocket/warning-event/${token}`
        } else {
            const { devHost, socket_process } = this
            return token => process.env.NODE_ENV == 'development'
                ? `ws://${devHost}/websocket/warning-event/${token}`
                : `${socket_process}${window.location.host}/api/websocket/warning-event/${token}`
        }
    }

    getProjectName(){
        if (isExeMode()) {
            const { url } = this.getExeConfig()
            return `https://${url}`
        }
        // 开发 | 生产 | 测试
        return 'api'
    }

    getWsUrl(){
        if (isExeMode()) {
            const { socket_process, url } = this.getExeConfig()
            return `${socket_process}${url}`
        } else {
            const { devHost, socket_process } = this

            let devWsUrl = ''
            // 开发
            if (isDev()) {
                devWsUrl = `ws://${devHost}`
            }
            // 测试
            if (isTest()) {
                this.devHost = 'lab.it9g.com/api'
                devWsUrl = `wss://${devHost}`
            }

            return process.env.NODE_ENV == 'development'
                ? devWsUrl
                : `${socket_process}${window.location.host}/api`
        }
    }
}

const config = new Config()

export const projectName = config.getProjectName()

export const wsUrl = config.getWsUrl()

// message ws
export const getMessageWs = config.getMessageWs()

/**
 * 预警事件-状态
 */
export const WARNING_EVENT_STATE = {
    '0': '预警中',
    '1': '已解除'
}

export const WARNING_EVENT_SECURE_TYPE = {
    '0': '自动解除',
    '1': '手动解除'
}
