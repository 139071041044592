<template>
  <div style="background: #ffffff;border-radius: 8px;padding: 12px 24px 24px 24px">
    <div class="fontSize20gay text-left">第 {{pageForm.week}} 周整洁评分</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
      <div style="width: 800px;margin: auto">
        <el-form :model="pageForm" :rules="pageFormRules" ref="formRef">
          <el-form-item label="得分" prop="score" size="large" label-width="160px">
            <el-input v-model="pageForm.score" placeholder="请输入分数" @blur="pageForm.score=$event.target.value" oninput="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" style="width: 100px" />
            <span class="fontSize14gay99 marginLeft10">最大 100 分</span>
          </el-form-item>
          <el-form-item label="照片(最多3张)" prop="image" size="large" label-width="160px">
            <el-upload v-model:file-list="imageList" list-type="picture-card" :limit="3" accept=".jpg,.png,.jpeg"
                       :before-upload="imageUpload"
                       :on-preview="showImage">
              <el-icon><Plus /></el-icon>
            </el-upload>
            <el-dialog v-model="showImageVisible">
              <img w-full :src="showImageUrl" alt="Preview Image" style="width: 100%" />
            </el-dialog>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="marginTop15px">
      <button class="subBtn" @click="subData">保存</button>
      <button class="qxBtn" @click="qxBtn">取消</button>
    </div>
    <el-dialog v-model="showImageVisible">
      <img w-full :src="showImageUrl" alt="Preview Image" style="width: 100%" />
    </el-dialog>
  </div>
</template>

<script>
import {uploadFile} from "@/api/notice";
import {getRoomExamineScoreInfo, saveRoomExamineScore} from "@/api/api";
import {projectName} from "@/utils/config";
import ImageList from "@/view/patrolInspection/ImageList";

export default {
  name: "laboratoryEquipment",
  components: {ImageList},
  data(){
    return {
      imageList:[],
      showImageUrl:'',
      showImageVisible:false,
      pageForm: {
        id:'',
        week: '',
        laboratoryId: '',
        score: '',
        img: '',
      },
      page:1,
      pageFormRules: {
        date: [
          { required: true, message: '请选择日期', trigger: 'blur' },
        ],
        laboratoryId: [
          { required: true, message: '请选择实验室', trigger: 'blur' },
        ],
      },
      id: null,
    }
  },
  mounted() {
    //this.init();
    let data=this.$root.useRoute.query;
    if(data.laboratoryId && data.week){
      this.pageForm.week=data.week;
      this.pageForm.laboratoryId=data.laboratoryId;
      //this.$refs.imageList.setImageInfo(this.pageForm.img);
    }
    this.page=data.page;
    if(data.weekId){this.pageForm.id=data.weekId;this.getWeekScoreInfo(data.weekId);}
  },
  methods: {
    //上传图片
    imageUpload(e){
      let formData= new FormData();
      let fileName=e.name;
      formData.append('file',e);
      uploadFile(formData).then((res)=>{
        if(res.success){
          this.imageList.push({name:fileName,url:projectName+res.data})
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    //放大查看图片
    showImage(e){
      this.showImageUrl=e.url;
      this.showImageVisible=true;
    },
    getWeekScoreInfo(id){
      getRoomExamineScoreInfo(id).then(res=>{
        if(res.data){
          this.pageForm=res.data;
          if(this.pageForm.img){this.setInitImge(this.pageForm.img);}
        }
      })
    },
    setInitImge(imgText){
      let imgList=imgText.split(",");
      let img=[];
      for (let i in imgList){
        img[i]={url:projectName+imgList[i]}
      }
      this.imageList=img;
    },
    subData(){
      let imgText='';
      if(this.pageForm.score>100){return this.$root.ElMessage.error('最大分数不能超过100');}
      if(this.imageList && this.imageList.length>0){
        for (let i=0;i<this.imageList.length;i++){
          if(i==0){imgText=imgText+this.imageList[i].url.replace("api","")}else{imgText=imgText+','+this.imageList[i].url.replace("api","");}
        }
        this.pageForm.img=imgText;
      }
      let type='post';
      if(this.pageForm.id){type='put';}
      saveRoomExamineScore(this.pageForm,type).then(res=>{
        if(res.success){
          this.$root.ElMessage.success(res.message);
          this.qxBtn();
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    qxBtn(){
      this.$root.useRouter.push({path:"/room/roomDataList",query:{page:this.page}});
    },
  }
}
</script>

<style lang="scss" scoped>

</style>